@import url('https://fonts.googleapis.com/css2?family=Exo:wght@200&family=Kanit:ital,wght@0,400;1,300&display=swap');
.containerrr{
    text-align: center;
    padding-top: 100px;
    /* background-color: rgb(209, 247, 249); */
    background: linear-gradient(rgba(255, 255, 255, 0.842),rgba(208, 229, 237, 0.861),rgba(20, 160, 220, 0.774));
    padding-bottom: 30px;
    min-height: 900px !important;
    /* min-height: 150vh; */
  /* height: 100% !important;  */

     /* background: url("https://thecapplug.com/img/cms/94599-contact-us.gif");
     background-repeat: no-repeat;
     background-size: cover; */
     /* background-size: 200vh 80vw; */
}

.head_1{
    color: rgb(128, 123, 123);
    font-family: 'Kanit', sans-serif;
}
.heade_2{
    font-family: 'Kanit', sans-serif;
}
.contact{
    display: flex;
    justify-content: space-around;
    margin: 30px;


}
/* .contact_1:hover{

} */
.contact_1{
    background-color: white;
    padding:10px 30px;
    box-shadow: 5px 7px 7px 7px #2b2c2c0b;
}
.contact_1 h1{

        font-size: 20px;
        color: rgb(21, 21, 21);

        font-family: 'Kanit', sans-serif;

}
.contact_1>h1>i{
    margin-right: 10px;
    padding-bottom: -30px;
    font-size: 30px;
}
.contact_1 a{
    color: black;
    /* background-color: rgb(40, 252, 54); */
    padding: 5px;
    text-decoration: none;
    font-size: 20px;

}
.container-con>h1{
    text-align: center;
}
.container-con-1{
    box-shadow: 5px 7px 7px 7px #2b2c2c0b;

}
.container-con{
    display: flex;
    min-height: 500px;
    margin-top: 0px;
    /* background-color: white; */
}
input{
    background-color: rgb(239, 255, 234) !important;

}
input::placeholder{
    color: black;
    font-weight: 500;
}
textarea{
    background-color: rgb(239, 255, 234) !important;

}
.formm{
    width: 40%;
    padding: 39px;
    margin-right: 10px;
    background-color: white;
}
.formm>h2{
    padding-bottom: 10px;
    font-size: 40px;
}
.formm_1{
    width: 60%;
}
iframe{
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: white;
}
form{
    text-align: center;
    font-family: 'Exo', sans-serif;
}
input{
    border-radius:0px !important;
}

@media only screen and (max-width: 770px) {
    .containerrr{
        height: 1600px !important;
    }
    .formm_1{
        width: 100%;
        margin-bottom: 20px;
        height: 10vh;
    }
    .formm{
        width: 100%;
        margin: 10px 0px;
    }
    textarea{
        width: 100%;
    }
    .heade_2{
        font-size: 30px;
    }

    .contact{
        /* display: block; */
        justify-content: space-between;
        margin: 20px;
    }
    /* .contact_1{
        margin: 0px 0px;
        font-size: 10px;
    } */
    .contact_1{
        background-color: white;

        padding:10px 0px;
        box-shadow: 5px 7px 7px 7px #2b2c2c0b;
        width: auto;
        margin: 10% -10px !important;
    }
  }
  @media only screen and (max-width: 1200px) {
    .about_data{
        text-align: center;
        padding: 20px 25px ;
    }
    .containerrr{
        height: 1200px !important;
    }
    .container-con{
        display: block;
        /* height: 140vh; */
        margin-top: 0px;
    }
    .formm{
        width: 100%;
        margin: 10px 0px;
    }
    .formm>h2{
        font-size: 30px;

    }
    textarea{
        width: 100%;
    }
    .formm_1{
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
    }

  }
  @media only screen and (max-width: 992px) {
    .contact{
        display: block;
        text-align: center;
        margin: 30px;


    }
    .containerrr{
        height: 1480px !important;
    }
    .contact_1{
        background-color: white;

        padding:10px 0px;
        box-shadow: 5px 7px 7px 7px #2b2c2c0b;
        width: auto;
        margin: 20px 180px;
    }
  }
.custom-shape-divider-top-1693549190 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

