.labourlaw{
    /* padding-top: 7%; */
    height: auto;
    background-color: rgb(255,255,255) !important;
}
.labourhead1{
height: 700;
/* background-image: url('https://uploads.onsize.com.br/cndl/varejosa/2022/03/15082800/shutterstock_1135205069-1140x694.jpg'); */
background-repeat: no-repeat;
background-size: cover;
background-position: center;
/* background: linear-gradient(rgba(250, 249, 249, 0.08),rgba(164, 139, 139, 0.016),rgba(220, 216, 216, 0.18)); */
}
.labourhead11{
    height: 700px;
    background: linear-gradient(rgba(253, 254, 254, 0.957),rgba(218, 241, 251, 0.779),rgba(55, 164, 248, 0.18));
}


.labourhead1>div>div>h1{
    padding-top: 12%;
    font-size: 50px;
    color: rgb(39, 52, 66);
    /* color: rgb(74,94,116); */
}
.labourhead1>div>div>h5{
    padding-top: 1%;
    font-size: 30px;
    color: rgb(36, 56, 75);
}
.labourhead1>div>div>h1>span{
    color: rgb(64, 65, 65);
    font-size: 50px;
}
.labourhead1>div>div>p{
    font-size: 25px;
    font-weight: bold;
}
.labourhead2body-img>img{
    height: 230px ;
    width: 50%;
}
.labourlawhead{
    color: rgb(65, 169, 248);
}
.labour-last-img{
    height: 300px !important;
    width: 50%;
}
.labour-display-1{
    display: none;
}
.labour-display-2{
    display: flex;
}
.labourhead2body-img5>img{
    height: 250px !important;
    width: 35%;
}
@media only screen and (max-width: 992px) {
    .labourhead1{
        height: 500px;
    }
    .labourhead11{
        height: 500px;
    }
    .labourhead1>div>div>h5{
        font-size: 30px;
        /* color: rgb(74,94,116); */
    }
    .labourhead1>div>div>h1{
        font-size: 50px;
    }
    .labourhead1>div>div>h1>span{
        font-size: 40px;
    }

}
@media only screen and (max-width: 770px){
    .labourhead1{
        height: 400px;
        background-size: cover;
        background-position: center;
    }
    .labourhead11{
        height: 400px;
    }
    .labour-display-1{
        display: flex;
    }
    .labour-display-2{
        display: none;
    }
}
@media only screen and (max-width: 450px){
    .labourhead1{
        height: 350px;
        background-size: cover;
        background-position: center;
    }
    .labourhead11{
        height: 350px;
    }
    .labourhead1>div>div>h5{
        font-size: 20px;
    }
    .labourhead1>div>div>h1{
        font-size: 35px;
    }
    .labourhead1>div>div>h1>span{
        font-size: 30px;
    }
    .labour-last-img{
        height: 200px !important;
        width: 50%;
    }
    .labourhead2body-img5>img{
        height: 250px !important;
    }
}


