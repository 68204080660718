.app-container {
    position: relative;
  }
  .animation-div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2; /* Ensure the animated div is above the content */
    /* Add your animations or styles here */
    transition: opacity 1s;
  }

  .content {
    opacity: 0; /* Initially, the content is invisible */
    transition: opacity 1s;
  }

  .fade-out {
    opacity: 0;
    pointer-events: none; /* Ensures that the div won't block interactions */
  }

  .fade-in {
    opacity: 1;
  }
  html, body {
    overflow-x: hidden;
  }
/* .dropdown-menu {
    transform: translateX(-60px) !important;
}

@media only screen and (max-width: 991px){
    .dropdown-menu {
        transform: translateX(0px) !important;
    }
} */
