@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:200');

.home121  {
    background-image: url('https://i.stack.imgur.com/i6NG3.gif');
    background-size:cover;
    background-repeat: no-repeat;
    height: 620px;

}


.home121color{
  background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(3, 119, 168, 0.664));
 padding: 0px 0px !important;
 /* position: absolute; */
 padding: -20px !important;
 top: 0;
 right: 0;
 bottom: 0;
 left: 0;
 margin: -0px;
 padding: -60px;
}

.cente
{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
   border-radius: 3px;
  height: 620px;
        padding: -10px;
}
.cente h1{
  text-align:center;
  color:rgb(4, 4, 4);
  text-transform:uppercase;
  padding: 100px 140px;
  font-size: 40px;
}
.home1btn{
    background: linear-gradient(rgb(49, 10, 220),rgb(12, 9, 174) );
    font-size: 20px;
    text-decoration: none;
    color: rgb(255, 255, 255);
    padding: 12px 40px ;
    border-radius: 30px;
    cursor: pointer;
}
.home1btn:hover{
    background: linear-gradient(rgb(3, 2, 58),rgb(49, 10, 220) ) ;
}
@media only screen and (max-width: 1200px) {
  .cente h1{
    text-transform:uppercase;
    padding: 50px;
    font-size: 40px;
  }
  .cente{
    height: 500px;
  }
  .home1btn{
    font-size: 15px;
    padding: 10px 20px ;
    border-radius: 30px;
    cursor: pointer;
}

  .home121  {
    height: 500px;
    background-size:cover;
  }
  @-webkit-keyframes slidein {
    from {background-position: top; background-size:200px; }
    to {background-position: -400px -600px;background-size:1750px;}
    }

    @keyframes slidein {
    from {background-position: top;background-size:700px; }
    to {background-position: -300px -300px;background-size:1750px;}

    }
}
@media only screen and (max-width: 992px) {
  .cente h1>p{
    font-size: 30px;
  }
  .cente>h1>h2{
    font-size: 25px;
  }
  .home121  {
    height: 450px;
    background-size:cover;
  }
}
@media only screen and (max-width: 600px){
    .home121  {
        height: 400px;
        background-size:cover !important;
        background-position: center;
        margin: auto;
      }
      .cente h1>p{
        font-size: 25px;
      }
      .cente>h1>h2{
        font-size: 20px;
      }
}
@media only screen and (max-width: 400px){
    .home121  {
        height: 400px;
        background-size:cover !important;
        background-position: center;
        margin: auto;
      }
      .cente h1>p{
        font-size: 20px;
      }
      .cente>h1>h2{
        font-size: 15px;
      }
      .home1btn{
        font-size: 13px;
        padding: 5px 10px ;
    }
}
