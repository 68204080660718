.stckydiv>a{
    /* background-color: green; */
    background-color: rgb(13, 128, 5);
    color: white !important;
    padding: 6px 40px ;
    text-decoration: none;
    border-radius: 20px;
    position: fixed;
    z-index: 1;
  bottom: 120px;
  cursor: pointer;
  left: -90px;
  display: inline;
    font-size: 18px;
  transform: rotate(-90deg);
}
.callbtn{
    position: fixed;
    z-index: 1;
  bottom: 150px;
  cursor: pointer;
  right: 10px;

}
.msgbtn{
    position: fixed;
    z-index: 1;
  bottom: 90px;
  cursor: pointer;
  right: 10px;

}
.divdis{
    display: none;
}


@media only screen and (max-width: 1200px) {
    .stckydiv>a{
        padding: 0px 30px ;
        font-size: 15px;
        margin: 20px;
    }
    .call-icon{
        font-size: 20px;
    }
    .number{
        font-size: 15px;
        padding-bottom: 9.5px;
    }
    .msg-icon{
        font-size: 20px;
    }
    .msg{
        font-size: 17px;
    }

}
@media only screen and (max-width:770px) {
    .call-icon{
        font-size: 15px;
    }
    .number{
        font-size: 10px;
        padding-bottom: 9.5px;
    }
    .msg-icon{
        font-size: 15px;
    }
    .msg{
        font-size: 10px;
    }
}
/* call button  */
/* src/components/PhoneLink.css */
.call-icon{
    font-size: 17px;
    /* padding: 12px 0px; */
    background-color: transparent !important;
    border-radius: 50%;
    color: white;
}
.number{
    font-size: 19px;
    background-color: transparent !important;
    padding-bottom: 9.5px;
    /* display: none; */
}
.phone-link{
    background-color: rgb(13, 128, 5);
    border-radius: 50%;

}
.phone-link:hover{
    border-radius: 50px  20px 20px 50px;
}
.msg-icon{
    font-size: 17px;
    background-color: transparent !important;
    border-radius: 50%;
    color: white;
}
.msg{
    font-size: 20px;
    background-color: transparent !important;
    padding-bottom: 9.5px;
    /* display: none; */
}
.msg-link{
    background-color: rgb(13, 128, 5);
    border-radius: 50%;

}
.msg-link:hover{
    border-radius: 50px  20px 20px 50px;
}
.phone-number{
    text-decoration: none;
    color: white;
}
.phone-number{
    text-decoration: none;
    color: white;
    position: relative;
}
.msg-id{
    text-decoration: none;
    color: white;
    position: relative;
}


