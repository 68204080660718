.miniTitle{
  font-size: 40px;
  color: rgb(65, 169, 248);
}
.shadow-effect {
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    text-align: center;
    height: 600px;
    width: 450px;
    margin: auto;
    border: 1px solid #ECECEC;
    box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
  }

  /* .shadow-effect>p {
    font-family: inherit;
    font-size: px;
    line-height: 1.5;
    margin: 0 0 1px 0;
    font-weight: 300;
  } */
  .img-circle {
    border-radius: 50%;
    vertical-align: middle;
    max-width: 90px;
    min-height: 90px;
    transform-style: preserve-3d;
    margin: 0 auto 17px;
    border: 5px solid rgb(249, 246, 246);
    box-shadow: 2px 1px 10px 10px rgba(0, 0, 0, 0.059), 0px 5px 7px rgba(0,0,0,0.5);
  }
  #customer-testimonoals {
    margin-top: 3%;
    z-index: auto !important;
  }
  #testimonial{
    /* background-color: rgb(241,241,241) !important; */
    background-color: rgb(245, 245, 245) !important;
    margin-top: -50px;
  }
  .testimonial-name {
    margin: -50px auto 0;
    display: table;
    width: auto;
    background-color: transparent !important;
    padding: 10px 0px;
    border-radius: 12px;
    text-align: center;
    color: #fff;
  }
  #customer-testimonoals .item {
    text-align: center;
    padding: 40px;
    margin-bottom: 0px;
    opacity: 0.2;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.9s ease-in-out;
  }
  #customer-testimonoals .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.0, 1.0, 1);
    background:linear-gradient(#afd2d2,rgb(175, 219, 219),rgb(230, 251, 252));
  }
  .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    /* background: #56423D; */
    transform: translate3d(0px, -50%, 0px) scale(0.8);
  }
  .owl-carousel .owl-dots {
    display: inline-block;
    width: 100%;
    text-align: center;
  }
  .owl-carousel .owl-dots .owl-dot{
    display: inline-block;
  }
  .owl-dots>.active{
    /* background-color: rgb(241, 241, 241) !important; */
    background-color: rgb(234, 234, 234) !important;
  }

  .owl-carousel .owl-dots .owl-dot span{
    /* background: #eaa400; */
    background: #fd3402;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 2px 5px;
    transform: translate3d(0px, -50%, 0px) scale(0.3);
    transform-origin: 50% 50% 0;
    transition: all 350ms ease-out 0s;
    transform: 5s;
  }
  .miniTitle{
    font-size: 40px;
    color: rgb(65, 169, 248);
  }
  .shadow-effect>p{
    font-size: 12px ;
    text-align: justify;
    text-justify: inter-word;
    text-align: center;
    margin: 10px;
    overflow: auto;
  }

  @media only screen and (max-width: 1399px) {
    .shadow-effect {
      padding: 5px;
      height: 350px;
      width: 270px;
    }
    .shadow-effect>p{
      font-size: 12px ;
    }
    .item{
      padding: -10px;
    }
  }
   @media only screen and (max-width: 1200px) {
    .miniTitle{
      font-size: 30px;
    }
    .shadow-effect>p{
      font-size: 10px ;
      text-align: justify;
      text-justify: inter-word;
      text-align: center;
      margin: 5px;
    }
    .item{
      padding: 20px !important;
    }
    .shadow-effect>h5{
      font-size: 15px;
    }
    .shadow-effect>small{
      font-size: 10px;
      margin-top: -20px;
    }
    .shadow-effect {
      padding: 10px;
      height: 230px;
      width: 270px;
    }

    .testimonial-name {
      margin: -50px auto 0;
    }
    .img-circle {
      border-radius: 50%;
      vertical-align: middle;
      max-width: 80px;
      min-height: 80px;
    }

   }
   @media only screen and (max-width: 992px) {
    .shadow-effect {
      background: #fff;
      padding: 5px;
      height: 180px;
      width: 210px;
      border: 1px solid #ECECEC;
      box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
    }
    .item{
      padding: 10px  !important;
    }
    .shadow-effect>p{
      font-size: 9px ;
    }
    .item{
      padding: 0px;
    }
   }
   @media only screen and (max-width: 770px) {
    .shadow-effect {
      background: #fff;
      padding: 5px;
      height: 140px;
      width: 130px;
      border: 1px solid #ECECEC;
      box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
    }
    .item{
      padding: 20px !important;
    }
    .shadow-effect>p{
      font-size: 7px ;
    }
    .shadow-effect>p{
      font-size: 7px ;
    }
    .img-circle {
      max-width: 40px;
      min-height: 50px;
      transform-style: preserve-3d;
      margin: 0 auto 13px;
      border: 5px solid rgb(249, 246, 246);
      box-shadow: 2px 1px 10px 10px rgba(0, 0, 0, 0.059), 0px 5px 7px rgba(0,0,0,0.5);
    }
    .testimonial-name {
      margin: -30px auto 0;
    }
    .shadow-effect>h5{
      font-size: 10px;
    }
    .shadow-effect>small{
      font-size: 10px;
      padding: -30px !important;
    }
   }
   @media only screen and (max-width: 599px) {
    .shadow-effect {
      background: #fff;
      padding: 0px;
      height: 350px;
      width: 100%;
      margin: 0px !important;
      padding: 30px !important;
      border: 1px solid #ECECEC;

      box-shadow: 0 19px 38px rgba(0,0,0,0.10), 0 15px 12px rgba(0,0,0,0.02);
    }
    .item{
      padding: 0px  !important;
    }
    .shadow-effect>h5{
      font-size: 25px;
    }
    .shadow-effect>small{
      font-size: 15px;
      padding: -5px !important;
    }
    .shadow-effect>p{
      font-size: 15px;
    }
    .img-circle {
      max-width: 90px;
      min-height: 90px;
      }
    .testimonial-name {
      margin: -50px auto 0;
    }

   }
