@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

.modal-header{
    background-color: rgb(16, 158, 6) !important;
    /* background:linear-gradient(rgb(0, 105, 253),rgb(95, 134, 213)); */
    /* color: white; */
}
.modal{
    margin-top: 30px !important;
}
.modal-backdrop{
    background-color: rgba(88, 107, 244, 0.24) !important;
}
.modal-backdop{
    background-color: aquamarine !important;
}
.registerback{}
.bod{
    background-color: rgba(225, 225, 255, 0.119);
    height: 200px;
}
.demomodal>h1{
    /* text-align: center; */
    display: none;
}
.modal-header>button{
    border: none;
    background: transparent;
}
.modal-header>button>img{
    height: 30px;
    width: 30px;
}
.regformhead{
    display: flex;
}
.modal-width{
    width: 60%;
}
.userName{
    display: flex;
    justify-content: space-between;
}
.userName>div>input{
    border-radius: 5px;
    margin: 0px;
    width: 97%;
}
.userdata{
    display: flex;
    justify-content: space-between;
}
.userdata>div>input{
    border-radius: 5px;
    margin: 0px;
    width: 97%;
}
.regform>div>input::placeholder{
    color: rgb(155, 153, 153);
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}
.regform>div>input{
    color: rgb(6, 6, 6);
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}
.regform>div>input{
   width: 98%;
}
.userName>div>input::placeholder{
    color: rgb(161, 159, 159);
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}
.userName>div>input{
    color: rgb(7, 7, 7);
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}
.userdata>div>input::placeholder{
    color: rgb(161, 159, 159);
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}
.userdata>div>input{
    color: rgb(5, 5, 5);
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}
input{
    font-weight: 600 !important;
}
.form-check-input:checked{
    background-color: rgb(13, 13, 13) !important;
    color: rgb(11, 11, 11) !important;
}
.regform>button{
    /* color: rgb(161, 159, 159); */
    font-weight: 600;
    font-family: 'Open Sans', sans-serif;
}

