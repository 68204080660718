@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@900&display=swap');
h1{
    font-family: 'Overpass', sans-serif;
}
.custom-duration--1 {
    animation-duration: 1s !important;  /* Adjust the time as needed */
  }

.animate__animated.animate__backInDown {
    --animate-duration: 3s;
    --animation-deday: 3s;
  }
.home2{
    padding: 20px;
    background-color: rgb(255,255,255);
}

/* .header{
    text-align: center;
    padding: 250px 100px;
    color: rgb(42, 28, 243);
}
.header>p{
    font-size: 30px;
} */
.home2data{
    display: flex;
    margin: 40px;
}
.anim{
    width: 50%;
}
/* .animImg{
    width: 20px;
} */
.animImgs{
    position: relative;
    top: 40px;
    height: 450px;
    width: 45vw;
}
.home2head{
    text-align: center;
}
.home2head>h1{
    font-size: 40px;
    color: rgb(65, 169, 248);
}
/* .home2head>h2{
    font-size: 30px;
    color: rgb(75, 182, 240);
} */
.anim>p{
    font-size: 15px;
    margin: 10px 0px 40px 0px ;
}


.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 20px;
    background: transparent;
    color: rgb(4, 90, 119);
    cursor: pointer;
    border-radius: 100px;
    z-index: 1;
    border: none;
    /* box-shadow: 0 5px 10px #ccc; */
  }

.home3{
    background: linear-gradient(#cfeaea,rgb(207, 232, 231),rgb(174, 211, 239));
    min-height: 400px;

}

.home3header{
    font-size: 40px;
    color: rgb(65, 169, 248);
   }
.anim>p{
    text-align: justify;
    text-justify: inter-word;
}
.home1p{
    text-align: center;
    /* text-justify: ; */
}
.home3con2ach>ul>li>a{
    font-size: 30px;
    text-decoration: none;
    color: rgb(2, 2, 2);
    text-align: left !important;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bolder;
}





.home3con2ach>ul {
	font-size: 30px;
	line-height: 1.5;
	list-style: none;
    justify-content: center !important;
}
.home3con2ach>ul>li::marker {
    font-size: 50px !important;
  }
  .home3con2ach>ul>li>a:hover{
    color:rgb(255, 0, 0) !important ;
  }
.home3con2ach>ul>li {
	position: relative;
	font-weight: 700;
	transition: .25s ease-in-out;
}

.home3con2ach>ul>li:hover {
	color: hsl(25, 83%, 52%);
}


.home3con2ach>ul>li::before {
	content: "";
	position: absolute;
	z-index: 1;
	left: -.75em;
	top: 46%;
	width: .3em;
	height: .3em;
	box-sizing: border-box;
	border-radius: 100%;
	border: .1em solid hsl(0, 0%, 20%);
	background-color: hsl(200, 100%, 50%);
	transition: .125s ease-in-out;
	box-shadow: 0 0 .1rem .1rem hsla(0, 0%, 50%, .25);
	animation: pulse 1s 1s infinite linear alternate;
}

@keyframes pulse {
	from {
		background-color: hsl(200, 100%, 50%);
	}
	to {
		background-color: hsl(200, 0%, 20%);
	}
}

.home3con2ach>ul>li:hover::before {
	border-width: 0;
	background-color: hsl(0, 100%, 50%);
	box-shadow: 0 0 .5rem .1rem hsla(0, 100%, 50%, 1);
	animation: none;
}
.home3con2{
/* width: 70%; */
color: rgb(22, 23, 23);

}
.justifycontentend{
    justify-content: left;
}
.justifycontentend{
    justify-content: right;
}
.justifycontentstart{
    justify-content: left;
}
.service-img{
    height: 260px;
}
.service-link>div>h3{
    margin-bottom: 30px;
    border-radius: 10px;
    background-color: rgb(3, 137, 182);
    text-align: center;
    /* text-justify: inter-word; */


}
.service-link>div>h3>a{
    text-decoration: none;
    color: rgb(229,229,229);

}
.home3con2>h1{
    font-size: 40px;
    padding-top: 30px;
    color: rgb(65, 169, 248);
    text-align: center;
}
.home3con3{
    position: relative;
    top: 200px;
    width: 25%;
}
.home4head{
    text-align: center;
    padding: 40px;
}
.home4head>h1{
    /* color: rgb(2, 2, 66); */
    color: rgb(65, 169, 248);
    font-size: 40px;
}
.home3con2ach>a{
    text-align: left ;
}
.home4head>p{
font-size: 10px;
}
.home4{
 /* background-color: rgb(224, 239, 239); */
 background: linear-gradient(rgb(233, 232, 232),#afd2d2,rgb(207, 232, 231));
}
.home4card>img{
    height: 80px;
    margin: 10px;
    width: 80px;
}
.home4card>h1{
    font-size: 16px;

}
.home4card>p{
    font-size: 15px;
    text-justify: none !important;
    text-align: center !important;
}
.home4cardprob8>h1{
    font-size: 23px;

}
.home4cardprob8>p{
    font-size: 20px;

}
.home4card{
    text-align: center;
    width: 280px;
    border: 1px solid black;
    margin: 10px;
    height: 300px;
    padding: 30px;
    background-color: white;
    transition: 0.5s;
    border-radius: 15px;
    margin: 20px ;


}

.home4cardr1instus>.home4card>img{
    height: 190px ;
    width: 190px;
    /* border-radius: 50%; */
    margin: 0px 0px 30px 0px;
}
.home5{
    /* background-color: rgb(211, 213, 243); */
    background: linear-gradient(rgb(215, 244, 242),#9ebdbd,rgb(215, 249, 247));
    padding: 50px 0px;
    text-align: center;
    justify-content: space-around;
}
.home5head>h1{
    text-align: center;
    font-size: 40px;
    margin: 30px;
    color: rgb(65, 169, 248);
}

.home4card:hover {
    /* transform: scaleY(1.09,1.0); */
    transform: translate(-10px, -5px);
    transform: scale(1.03, 1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 17%, 19%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  }
.home4cardr1{
    display: flex;
    justify-content: space-around;

}

.home6{
    text-align: center;
    background-color: rgb(224, 250, 252);
    padding: 40px ;
}
.home6head>h1{
    font-size: 40px;
    color: rgb(65, 169, 248);
}
.home6card>h1{
    margin: 20px 0px 10px 0px;
    color: green;
}
.home6card>p{
    font-weight: bold;
    /* margin-top: -20px; */
    font-size: 30px;
}
.home6cardr1{
    display: flex;
    justify-content: space-around;
    text-align: center;

}

.home7card>img{
    /* height: 25vh; */
    /* width: 12vw; */
    height: 180px;
    width: 180px;
    margin: 20px 20px 30px 20px;
    border-radius: 50%;

}
.home7card>h1{
    font-size: 20px;
}
.home7card>h5{
    font-size: 15px;
}
.home7card{
    text-align: center;
    background-color: rgb(238, 239, 244);
    padding: 20px;
    border-radius: 5px;
    /* height: 60vh; */
    height: 380px;
    /* width: 25vw; */
    width: 300px;
}

.home7card>a{
    font-size: 40px;

}
/* .home7card>a:hover{
    font-size: 60px;

} */
.homecard7head{
    display: flex;
    justify-content: space-around;
}
.home7head>h1{
    text-align: center;
    font-size: 40px;
    margin-bottom: 30px;
    color: rgb(65, 169, 248);
}
.home7{
    padding: 40px;
    background: linear-gradient(rgb(230, 251, 252),#afd2d2,rgb(175, 219, 219));


}
.home7card:hover {
    /* transform: scaleY(1.09,1.0); */
    transform: translate(0, -20px);
    box-shadow: 0 13px 40px -5px hsla(240, 80%, 86%, 0.12), 0 8px 32px -8px hsla(206, 88%, 87%, 0.14), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.02);
  }
  .home7{
    padding-bottom: 100px;
  }


  .home8{
    padding-top: -300px;

  }
  .home888{
    background-color: rgb(245, 245, 245) !important;

  }
  @media only screen and (max-width: 770px) {
    .justifycontentend{
        justify-content: center;
        width: 100%;
    }
    .justifycontentstart{
        justify-content: center;
    }
}
@media only screen and (max-width: 700px) {
    .home{
        height: 40vh ;
    }
    .home6cardr1{
        display: block;
        text-align: center;

    }
    .home7{
        height: auto !important;
    }
    .activity-1st {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
      }
      .activity-2st {
        display: inline-block;
        width: 50%;
        box-sizing: border-box;
      }
    .header{
        text-align: center;
        padding: 100px 50px !important;
    }
    .homecard7head{
        display: inline;
        text-align: center;
        justify-content: center;

    }
    .home7card{
        width: 80% !important;
        margin: 30px 40px 30px 10% !important;
    }
    .home7card>img{
        height: 150px !important;
        width: 150px !important ;
    }
  }
  @media only screen and (max-width: 1200px) {

    .header{
        text-align: center;
        padding: 250px 100px;
    }

    /* .home4cardr1{
        display: block ;
        margin-left: 6%;
        justify-content: space-between;
    } */
    .home4card>h1{
        font-size: 16px;

    }
    .home5head>h1{
        text-align: center;
        font-size: 40px;
        margin: 10px;
        color: rgb(65, 169, 248);
    }

    /* .home4card>img{
        height: 50px;
        margin: 10px;
        width: 30;
    } */
    .home4card{
        height: 35vh;
    }
    .home7card{
        text-align: center;
        background-color: rgb(238, 239, 244);
        padding: 0px;
        border-radius: 5px;
        height: 400px;
        width: 25vw;
        margin: 10px;
    }
    .home7card>img{
        height: 180px;
        width: 13vw;
        margin: 20px 20px 30px 20px;
        border-radius: 50%;

    }
    .home7{
        height: 600px;
    }
    .home4card{
       height: 300px;


    }
    .home4cardprob8{
        padding: 20px 15px !important;
    }
    .home4cardprob8>img{
        height: 80px;
        width: 100px !important;
    }
    .home4cardprob8>h1{
        font-size: 20px !important;

    }
    .home4cardprob8>p{
        font-size: 15px !important;
    }
    .home4cardr1instus>.home4card{
        height:270px !important;
        width:20vw;
        margin: 20px;
        padding: 30px 0px;
    }
    .home4cardr1instus>.home4card>img{
        height: 150px ;
        width: 150px;
        /* border-radius: 50%; */
        margin: 0px 0px 30px 0px;
    }
    .home4card>img{
        height: 60px;
        margin: 20px;
        width: 60px;
    }
    .home4card>p{
        font-size: 13px;
    }
  }

  @media only screen and (max-width: 992px) {
    .home2data {
  display: flex;
  flex-direction: column;
}

.anim {
  order: 2;
}

.anim111 {
  order: 1;
}
.anim{
    width: 100%;
}
.animImgs{
    position: static;
    top: 0px;
    height: 400px;
    width: 65vw;
    display: block;
  margin-left: auto;
  margin-right: auto;
}
.home2head>h1{
    font-size: 30px;
}

h1{
    font-size: 30px ;
}
.home7{
    height: 550px;
}
.home7card{
    text-align: center;
    background-color: rgb(238, 239, 244);
    padding: 10px;
    border-radius: 5px;
    height: 350px;
    width: 29vw;
    margin: 10px;
}
.home7card>img{
    height: 100px;
    width: 100px;
    margin: 20px 20px 30px 20px;
    border-radius: 50%;

}
.home7card>h1{
    font-size: 25px !important;
}
.home7head>p{
    font-size: 15px;
}
.home4cardr1{
    display: inline;
    /* justify-content: space-around; */
    justify-content: space-between;

}
.home4card{

    width: 300px !important;

    height: 250px!important;
    padding: 0px 20px;
    margin: 10px ;


}
#home4card8>h1{
    font-size: 18px;
    margin: 2px !important;
}
#home4card8>img{
    height: 60px;

}

#home4card8>p{
    font-size: 13px !important;
    margin: 0px !important;
}
.home4card>img{
    height: 70px;
    width: 100px !important;
    margin: 20px;
}
.home4cardprob8{
    padding: 0px !important;
}
.home4cardprob8>img{
    height: 80px;
    width: 100px !important;
}
.home4cardprob8>h1{
    font-size: 17px !important;

}
.home4cardprob8>p{
    font-size: 20px;
}
.home4card>p{
    font-size: 15px;
}
.home4card>h1{
    font-size: 15px;
}
.home4cardr1>.home4card-r1 {
    display: inline-block;
    width: 30%;
    /* box-sizing: border-box; This ensures padding and border are included in the element's total width. */
  }
  .home4cardr1>.home4card-r2 {
    display: inline-block;
    width: 30%;
    /* box-sizing: border-box; This ensures padding and border are included in the element's total width. */
  }

  .home4cardhead{
    text-align: center;
  }
  /* .home4cardprob2{
    position: relative;
    top: 30px !important;
  }
  .home4cardprob2{
    position: relative;
    bottom: 30px !important;
  } */

  }
  @media only screen and (max-width: 500px){
    .animImgs{
        position: static;
        top: 0px;
        height: 250px;
        width: 65vw;
        display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media only screen and (max-width: 390px){
    .activity-1st {
        display: inline-block;
        width: 100%;
        box-sizing: border-box; /* This ensures padding and border are included in the element's total width. */
      }
      .activity-2st {
        display: inline-block;
        width: 100%;
        box-sizing: border-box; /* This ensures padding and border are included in the element's total width. */
      }
    .home4card>img{
        height: 100px !important;
        margin: 5px;
        width: 40px;
    }
    .home4card>h1{
        font-size: 15px;

    }
    .home4card>p{
        font-size: 10px;
        text-justify: none !important;
        text-align: center !important;
    }
    .home4cardprob8>h1{
        font-size: 18px;

    }
    .home4cardprob8>p{
        font-size: 10px;

    }
    .home4card{
        text-align: center;
        width: 150px !important;
        border: 1px solid black;
        margin: 1px;
        height: 300px !important;
        padding: 5px;
        background-color: white;
        transition: 0.5s;
        border-radius: 15px;
        margin: 20px ;


    }

    .home4cardr1instus>.home4card>img{
        height: 100px !important;
        width: 100px !important;
        /* border-radius: 50%; */
        margin: 0px 0px 10px 0px;
    }
  }
  @media only screen and (max-width: 390px){
    .home4head>h1{
        font-size: 30px;
    }
  }

