.statutory-1  {
    background-image: url(../../asset/services/statutory/Statutory-Banner.png);
    background-size:cover;
    background-repeat: no-repeat;
    height: 600px;
}

.statutory-11{
    background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(82, 193, 241, 0.664));
     height: 600px;
}
.statutoryconhead-1>h1{
    font-size: 50px;

}
.statutoryconhead-1>h5{
    font-size: 30px;
}
.statutoryconhead-3>div>div>img{
        height: 300px;
        width: 600px;
}
.statutoryconhead-3>div>div>p{
    text-align: justify;
    text-justify: inter-word;
}

.statutory-cont-data-head{
    color: rgb(65, 169, 248)    ;
}
.epf2{
    /* background-size: cover; */
    background: rgba(255, 0, 0, 0.521);
}
@media only screen and (max-width: 770px){
    .statutory-1  {
        background-image: url(../../asset/services/statutory/Statutory-Banner.png);
        background-size:cover;
        background-position: center;
    }
    .statuinrespongo{
        display: none;
    }
    .statutoryconhead-3>div>div>img{
        height: 300px;
        width: 300px;
}
.statutoryconhead-1>h1{
    font-size: 35x;

}
.statutoryconhead-1>h5{
    font-size: 25px;
}
}
@media only screen and (max-width: 400px){
    .statutoryconhead-3>div>div>img{
        height: 200px;
        width: 200px;
}
.statutoryconhead-1>h1{
    font-size: 30px;

}
.statutoryconhead-1>h5{
    font-size: 20px;
}
.statutory-1  {
    background-image: url(../../asset/services/statutory/Statutory-Banner.png);
    background-size:cover;
    background-position: center;
    height: 400px;
}

.statutory-11{
     height: 400px;
}
}
