.stafff-head-1
{
    height: 610px;
    background-position: center bottom;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(../../../Component/asset/Staffing-banar1.jpeg);
    /* width: 100%; */
}
.statfff-cont-data-head{
    color: rgb(65, 169, 248);
}
.stafff-head-1col-data>h1{
    font-size: 50px;
}
.stafff-head-1col-data>h5{
    font-size: 30px;
}
.stafff-head-1col{
    background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(82, 193, 241, 0.664));
    height: 610px;
}
.staff-head-img>img{
    height: 200px;
    width: 70%;
}
.staffing-display-1{
    display: none;
}
@media only screen and (max-width: 770px){
    .staffing-display-1{
        display: block;
    }
    .staffing-display-2{
        display: none;
    }
    .staff-head-img{
        display: flex;
        justify-content: center !important;
    }
    .staff-head-img>img{
        height: 200px;
        width: 60%;
    }
    .stafff-head-1
{
    height: 400px;
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(../../../Component/asset/Staffing-banar.jpeg);
    /* width: 100%; */
}
.stafff-head-1col{
    background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(82, 193, 241, 0.664));
    height: 400px;
}
.stafff-head-1col-data>h1{
    font-size: 30px;
}
.stafff-head-1col-data>h5{
    font-size: 20px;
}
}
