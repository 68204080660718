.hring-head-1
{
    height: 650px;
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(../../../Component/asset/hrring-banar.jpg);
    /* width: 100%; */
}
.hring-head-1col-data>h1{
    font-size: 50px ;

}
.hring-head-1col-data>h5{
    font-size: 24px;

}
.hring-cont-data-head{
    color: rgb(65, 169, 248);
}
.hring-head-1col{
    background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(82, 193, 241, 0.664));
    height: 650px;
}
.hring-head-img>img{
    height: 200px;
    width: 80%;
}
.hring-display-1{
    display: none;
}
@media only screen and (max-width: 770px){
    .hring-head-img>img{
        height: 200px;
        width: 400px;
    }
    .hring-display-1{
        display: block;
    }
    .hring-display-2{
        display: none;
    }
    .hring-head-img{
        display: flex;
        justify-content: center !important;
    }
    .hring-head-1
{
    height: 400px;
    background-position: center;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-image: url(../../../Component/asset/hrring-banar.jpg);
    /* width: 100%; */
}
.hring-head-1col{
    background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(82, 193, 241, 0.664));
    height: 400px;
}
.hring-head-1col-data>h1{
    font-size: 30px ;
}
.hring-head-1col-data>h5{
    font-size: 15px ;
}
}
@media only screen and (max-width: 400px){

}
