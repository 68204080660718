.footer{
    /* height: 64px !important; */
    /* background-color: rgb(53, 60, 92) !important; */
    background-color: rgb(6, 57, 105) !important;
    /* background: linear-gradient(rgb(241, 243, 244),rgba(163, 174, 180, 0),rgb(214, 239, 255)); */
    /* background: linear-gradient(rgb(228, 248, 255),rgb(194, 226, 223),rgb(201, 238, 237)); */

    /* font-size: 10px !important; */
    position: relative;
    bottom: 0; left: 0;
    width: 100%;
    padding: 1em;
    /* text-align: center; */
    flex-shrink: 0;
    left: 0;
    bottom: 0;
}
.social-media-btn>a
{
    font-size: 22px;
    /* color:#405DE6 !important; */
}
.insta{
    color:#ff60a5 !important;
}
.twitter{
    color:rgb(80,171,241);
}
.copyright{
    font-weight: bolder;
    /* margin-left: 60px !important; */
}
ul.contact-list{
    list-style-type: square;
    color: white;
}
.foolterImg{
    height: 30px;
    width: 150px;
    /* background-color: rgb(69, 68, 68); */
}
.underline-on-hover {
    position: relative;
    display: inline-block;
    color: #405DE6; /* Example color */
    border-radius: 15px !important;
  }

  .underline-on-hover::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #1B91DA; /* Color of the underline */
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .underline-on-hover:hover::after {
    visibility: visible;
    width: 100%;
  }
  .footertextatag{
    font-size: 12px;
    text-decoration: none;
    font-weight: 500;
    color: rgb(219, 219, 219);
    transition: text-decoration-color 0.3s, color 0.3s;
  }

  .footertextatag:hover {
    text-decoration: underline; /* Add underline on hover */
    text-decoration-color: #7b7d7e; /* Color of the underline */
  }
  .footertext{
    font-size: 12px;
    font-weight: bold;
    color: rgb(219, 219, 219);
  }
  .footersubheader{
    font-weight: bold;
    font-size: 14px;
    color: rgb(219, 219, 219);
  }
  .mainheader{
    font-weight: bolder;
    font-size: 18px;
    color: rgb(219, 219, 219);
  }
  .footertext1{
      color: rgb(219, 219, 219);
  }
@media only screen and (max-width: 766px){
    .footer{
        /* height: 144px !important; */
    }
    .copyright{
        text-align: center;
        margin-left: 0px !important;
    }
    .social-media-btn>a{
        /* padding-left: 30px !important; */
    }
}
