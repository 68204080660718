.pnf-img>img{
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
.pnf-img{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(247,249,251);
}
