.fullscreen-container {
    background-image: url(../Component/asset/ezgif.com-crop.gif);
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;  /* Fixed/Sticky position */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden; /* Hide scrollbars */
    /* transition: opacity 5s; */
    z-index: 99999;
}
.animate__animated.animate__backInDown{
    --animate-duration: 2s;
}

.immmmm>img{
    /* z-index: 999; */
    height: 90vh;
    width: 50vw;
}
/* App.css */
.immmmm {
    display: grid;
    place-items: center;
  }


