nav{
    position: fixed !important;
}
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Exo:wght@800&display=swap');
.nav-bar{
    background-color: transparent;
    /* background-color: rgb(164, 208, 242); */
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    position: sticky;
    top: 0;
    z-index: 999999;

}

#nav-bar{
    font-family: 'Kanit', sans-serif;
    font-size: 17px;
}
.dropdown>button{
    font-size: 17px;
}

.active{
    background: linear-gradient(rgb(254, 254, 255),rgb(255, 255, 255),rgb(246, 246, 249));
    color: rgb(12, 12, 12);

    box-shadow: 10px 10px 40px 10px hsla(240, 2%, 10%, 0.089), 0 8px 32px -8px hsla(206, 88%, 87%, 0.432), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.441);
}
.img_1{
    height: 30px;
    margin: 10px 10px ;

}
.dropdown-menu-1 {
    /* overflow: visible !important; */
    left: -80% !important;
}
@media only screen and (max-width: 700px) {
    .img{
        height: 25px;
    }
    .nav-bar{
        height: 60px;
    }
  }
  @media only screen and (max-width: 992px) {
    .nav-bar{
        background: linear-gradient(rgb(254, 254, 255),rgb(255, 255, 255),rgb(246, 246, 249));
        color: rgb(12, 12, 12);

        box-shadow: 10px 10px 40px 10px hsla(240, 2%, 10%, 0.089), 0 8px 32px -8px hsla(206, 88%, 87%, 0.432), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.441);

    }
    #navbarNavDropdown>ul{
        background: linear-gradient(rgb(254, 254, 255),rgb(255, 255, 255),rgb(246, 246, 249));
        color: rgb(12, 12, 12);

        box-shadow: 10px 10px 40px 10px hsla(240, 2%, 10%, 0.089), 0 8px 32px -8px hsla(206, 88%, 87%, 0.432), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.441);

    }
    .nav-bar>button{
        margin-bottom: 10px ;
        margin-right: 30px;
        border: none !important;

    }
   .nav-bar{
    height: 80px !important;
   }
    .nav-bar>button>span{
        color: rgb(252, 6, 6) !important;
    }
  }
  @media only screen and (max-width: 1200px) {
    .img{
        height: 35px;
    }
    #nav-bar{
        font-size: 17px;
    }
    .dropdown>button{
        font-size: 17px;
    }
    .img_1{
        height: 40px;
        margin: 10px 10px ;

    }
    .nav-bar{
        height: 60px;
    }
    .dropdown-menu-1 {
        /* overflow: visible !important; */
        left: -105% !important;
    }

  }
  @media only screen and (max-width: 383px){
    .img_1{
        height: 30px;
        margin: 10px 10px ;

    }
    .nav-bar{
        height: 30px;
    }
  }
  @media only screen and (max-width: 283px){
    .img_1{
        height: 22px;
        /* width: 80%; */
        margin: 10px 10px ;

    }
  }
