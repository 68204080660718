.MinwageSingledatamain
{
    min-height: 750px;
    background: linear-gradient(rgba(255, 255, 255, 0.842),rgba(227, 240, 246, 0.918),rgba(20, 160, 220, 0.774));
}
.minimum-wagesbklik{
    text-decoration: none;
}
.mw-header {
    display: none;
}

.mw-header:first-of-type {
    display: contents;
}
.minimunNDF>img{
    height: 300px;
    width: 100%;
}
.mindatanotfound{
    border: 2px solid black;
}
.wageTable>table{
    border-radius: 8px !important;
    /* border-color: rgb(0, 76, 255) !important; */
}
.wageTable>table>thead>tr>th
{
    background-color: rgba(41, 166, 250, 0.667) !important;
    color: white;
}
.wageTable>table>thead{
    background-color: white;
}
.wageTable>table>tbody>tr>td
{
    background-color: transparent;
    color: rgb(8, 8, 8);
    font-size: 12px;
}
