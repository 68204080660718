p{
    text-align: justify;
text-justify: inter-word;

}

.payrollheadwer-1  {
    background-image: url(../../asset/services/payroll/payroll-new-banner.png);
    background-size:cover;
    background-repeat: no-repeat;
    height: 600px;
}

.payrollheadwer-11{
    background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(82, 193, 241, 0.664));
     height: 600px;
}
.payrollheadcont>h1{
    font-size: 50px;

}
.payrollheadcont>h5{
    font-size: 30px;
}
.payrollheadcont>p{
    font-size: 20px;
}
.payrollhead2>div>h1{
    color: rgb(65, 169, 248);
}
.payrollhead2>div>div>div>img{
    height: 300px ;
    width: 500px;

}
#payrolladdbene{
    height: 300px ;
}
.ddd-flex{
    display: flex;
}
@media only screen and (max-width: 770px){
    .payrollheadwer-1  {
        background-size:cover;
        height: 400px;
    }
    .ddd-flex{
        display: block;
    }
    .payrollheadwer-11{
        height: 400px;
    }
    .payrollheadcont>h1{
        font-size: 30px;

    }
    .payrollheadcont>h5{
        font-size: 20px;
    }
    .payrollheadcont>p{
        font-size: 15px;
    }
    .payrollhead2>div>div>div>img{
        height: 250px ;
        width: 300px;
        box-shadow: 0 13px 40px -5px hsla(240, 80%, 86%, 0.12), 0 8px 32px -8px hsla(206, 88%, 87%, 0.14), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.02);
        margin-bottom: 20px;
    }
    #payrolldivorder1{
        order: 2 !important;
        text-align: justify;
    text-justify: inter-word;

    }
    #payrolldivorder2{
        order: 1 !important;
        text-align: center;
    }
    #payrolladdbene{
        height: 300px !important;
    }
}

  @media only screen and (max-width: 992px){
    .payrollheadwer-1  {
        background-size:cover;
        height: 500px;
    }
    .payrollheadwer-11{
        height: 500px;
    }
    .payrollhead2>div>div>div>img{
        height: 300px ;
        width: 250px;
        box-shadow: 0 13px 40px -5px hsla(240, 80%, 86%, 0.12), 0 8px 32px -8px hsla(206, 88%, 87%, 0.14), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.02);
    }
    .payrolladdbene{
        height: 300px !important;
    }
    }
  @media only screen and (max-width: 1200px){
    .payrollheadwer-1  {
        background-size:cover;
    }
    .payrollhead2>div>div>div>img{
        height: 300px ;
        width: 400px;
        box-shadow: 0 13px 40px -5px hsla(240, 80%, 86%, 0.12), 0 8px 32px -8px hsla(206, 88%, 87%, 0.14), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.02);
    }

    }
  @media only screen and (max-width: 890px){
    #payrollbenefit{
        margin-top: 30px;
        height: 300px;
        width: 330px;
    }
  }
  @media only screen and (max-width: 440px){
    #payrollbenefit{
        margin-top: 30px;
        height: 200px;
        width: 80vw;
    }
    .payrollhead2>div>div>img{
        height: 200px ;
        width: 80vw;
        box-shadow: 0 13px 40px -5px hsla(240, 80%, 86%, 0.12), 0 8px 32px -8px hsla(206, 88%, 87%, 0.14), 0 -6px 32px -6px hsla(198, 88%, 87%, 0.02);
    }
    .payrollhead2>div>div>div>img{
        height: 200px ;
        width: 90vw;
    }
  }

