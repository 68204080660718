@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');


@-webkit-keyframes slidein {
from {background-position: top; background-size:300px; }
to {background-position: -400px -600px;background-size:2750px;}
}

@keyframes slidein {
from {background-position: top;background-size:1300px; }
to {background-position: -400px -600px;background-size:2750px;}

}



#about
{
  align-items: center;
  justify-content: center;
  margin: auto;
  background: linear-gradient(rgba(255, 255, 255, 0.842),rgba(228, 242, 248, 0.918),rgba(20, 160, 220, 0.774));
  border-radius: 3px;
  min-height: 120vh;
  height: auto;
}
.about_data{
    text-align: center;
    padding: 60px 50px ;
}
.about_data>h1{

    color: rgb(21, 116, 232);
    padding-bottom: 20px;
}
.about_data p{
    font-size: 18px;
    text-align: justify;
    font-weight: 400;
    text-justify: inter-word;
}

.container-about{

    font-family: 'Kanit', sans-serif;
    background-repeat: no-repeat;
    background-size: cover;



    background-image: url('../asset/about-banar-gif.gif');


    /* padding-bottom: 30px; */
}
#about{
}
.about_cards{
    display: flex;
    justify-content: space-around;


}
.about_card{
    text-align: center;
    width: 33%;
    margin: 10px;
    /* border: 1px solid black; */
    border: 2px solid black;
    border-radius: 5%;
    /* background-color: rgb(189, 223, 252); */
    background: transparent;
    padding: 10px 40px;
    font-size: 18px;

}
.about_card:hover{
    box-shadow: 10px 10px 8px 10px #88888833;
    background-color: rgb(7, 80, 158);
    color: white;
}
#about{
    padding-top: 50px;
}
@media only screen and (max-width: 770px) {
    .about_data{
        text-align: center;
        /* padding: 0px 25px ; */

    }

    .about_data p{
        font-size: 15px;
    }

    .about_card{
        text-align: center;
        width: 100%;
        margin: 20px 0px ;
        /* border: 1px solid black; */
        font: 15px;

        border-radius: 20px;
        margin: 30px;
    }

  }
  @media only screen and (max-width: 1200px) {
    .about_data{
        text-align: center;
        padding: 20px 25px ;

    }

    .about_data p{
        font-size: 20px;
    }
    .about_card{
        font-size: 20px;
        margin: 10px 10px;
        padding: 10px 10px;
    }

  }
  @media only screen and (max-width: 992px) {
    .about_cards{
        display: block;
        padding-bottom: 10px;
        text-align: center;
        margin: 20px 20px 0px 0px;
    }
    .about_card{
        text-align: center;
        width: 100%;
        border-radius: 10px;
    }
    .container-about {
        padding-top: 30px;
    }
  }
