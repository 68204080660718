p{
    text-align: justify;
text-justify: inter-word;

}

.trainingheader-main  {
    background-image: url(../../asset/services/training/training-banar.webp);
    background-size:cover;
    background-repeat: no-repeat;
    height: 600px;
}

.trainingheader-11{
    background: linear-gradient(rgb(255, 255, 255),rgba(228, 236, 244, 0.514),rgba(82, 193, 241, 0.664));
     height: 600px;
}
.trainingheadcont>h1{
    font-size: 50px;

}
.trainingheadcont>h5{
    font-size: 25px;
}
.traininghead2>div>h1{
    color: rgb(65, 169, 248);
}
#trainingaddbene{
    height: 300px ;
}
@media only screen and (max-width: 770px){
    .trainingheader-main  {
        background-size:cover;
        height: 400px;
    }
    .traininglheader-11{
        height: 400px;
    }
    .payrollheadcont>h1{
        font-size: 30px;

    }
    .trainingheadcont>h5{
        font-size: 20px;
    }
    .trainingheadcont>p{
        font-size: 15px;
    }
    #trainingdivorder1{
        order: 2 !important;
        text-align: justify;
    text-justify: inter-word;

    }
    #trainingdivorder2{
        order: 1 !important;
        text-align: center;
    }
    #trainingaddbene{
        height: 300px !important;
    }
}

  @media only screen and (max-width: 992px){
    .trainingheader-main  {
        background-size:cover;
        height: 500px;
    }
    .trainingheader-11{
        height: 500px;
    }
    .trainingaddbene{
        height: 300px !important;
    }
    }
  @media only screen and (max-width: 1200px){
    .trainingheader-main   {
        background-size:cover;
    }

    }
  @media only screen and (max-width: 575px){
    .trainingheader-main  {
        background-size:cover;
    }
    .traininghead2>div>div>img{
        height: 200px ;
        width: 80vw;
    }
    .trainingheadcont>h1{
        font-size: 30px;

    }
    .trainingheadcont>h5{
        font-size: 15px;
    }
  }

