.mwhead-part
{
    /* border: 1px solid black; */
    padding-top: 20px;
    background-color: rgba(255, 255, 255, 0.457);
    border-radius: 5px;
}
/* .mw-select>select
{
    height: 40px !important;
    width: 200px;
    border-radius: 8px;
}*/
.mw-select
{
    padding: 0px !important;
}
.css-1jqq78o-placeholder{
    padding-left: 5px;
}
.mwhead-part>div>p{
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word;
}
.mw-container{
background: linear-gradient(rgba(255, 255, 255, 0.842),rgba(58, 186, 241, 0.918),rgba(20, 160, 220, 0.774));
min-height: 650px;
  }
.mwtable{
    /* border: 1px solid black; */
    border-radius: 6px;
    padding: 20px;
    margin-bottom: 50px;
}
.stateTableBorder{
    border: 0.1px solid rgb(168, 179, 188);
    border-radius: 5px;
    color: rgb(184, 66, 53);
    font-size: 12px;;
    background-color: rgba(203, 234, 255, 0.801);
}

.stateLink
{
    color: rgb(59, 8, 246);

    text-decoration: none;
    cursor: pointer;
}
.stateLink:hover{
    color: rgb(74, 168, 245);
}
.minhead{
    color: rgb(65, 169, 248);
}
.wages-state-color{
}
